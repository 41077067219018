.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 400px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 40%;
    top: 40%;
    box-sizing: border-box;
    transition: all 0.5s ease-out;

}

@media (max-width: 600px) {
    .Modal {
        width: 400px;
        left: calc(60% - 250px);
    }
}
.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
}
.modal-text{
    color: #333555;
    padding: 10px;
    text-align: center;
}
.modal-buttons{

    display: flex;
    justify-content: center;
    padding: 10px;
}
