/*=============================
  Theme Name: ----------------.
  Author: Mrh Ripon
  Support: mrhripon98@gmail.com
  Description: --------------.
  Version: 1.0
==============================*/


/* Base Css Start  */

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700,800,900&display=swap');
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");
html {
    font-size: 62.5%;
}

body {
    font-family: 'Nunito Sans';
    font-weight: normal;
    font-style: normal;
    background-color: #EEF1F4;
}

.fix {
    overflow: hidden;
}

p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 24px;
    color: #333555;
    margin-bottom: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Nunito Sans', ;
    font-weight: 700;
    color: #333555;
    margin-top: 0px;
}

h2 {
    font-size: 3.6rem;
}

h3 {
    font-size: 2.8rem;
}

h4 {
    font-size: 2.2rem;
}

h5 {
    font-size: 1.8rem;
}

h6 {
    font-size: 1.6rem;
}

a,
span {
    font-size: 1.6rem;
}

.img {
    max-width: 100%;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
    text-decoration: none;
    outline: none;
}

a:focus,
a:hover {
    text-decoration: none;
}

span,
a {
    display: inline-block;
}

ul,
ol {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

div[data-relative="yes"] {
    position: relative;
}


/* Base Css End */


/* header css start  */

.header-area {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 1px 5px rgba(51, 53, 85, 0.1);
    box-shadow: 0px 1px 5px rgba(51, 53, 85, 0.1);
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 5;
}

.header-f-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.user-part {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.user-part li:not(:first-child) {
    margin-left: 20px;
}

.user-part li {
    position: relative;
}

.user-part li ul.submenu {
    position: absolute;
    background: #333555;
    border-radius: 10px;
    right: -10px;
    width: 290px;
    top: 105%;
    padding: 30px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .3s;
    transition: .3s;
}

.user-part li:hover ul.submenu {
    opacity: 1;
    visibility: visible;
    top: 95%;
}

.user-img {
    text-align: center;
}

.user-img img {
    max-width: 90px;
    margin-top: 15px;
    margin-bottom: 30px;
}

.user-img h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #FFFFFF;
    margin-bottom: 3px;
}

.user-img p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #FFFFFF;
    margin-bottom: 35px;
}

.user-img button {
    margin-bottom: 10px;
}

.user-part li ul.submenu::after {
    position: absolute;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #333555;
    content: "";
    top: -19px;
    right: 15px;
}

.user-part li a {
    padding: 20px 0;
}

.user-part li .submenu.notification li {
    margin: 0;
}

.user-part li .submenu.notification li a {
    padding: 10px 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.005em;
    color: #888999;
}

.user-part li .submenu.notification li a span {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.005em;
    color: #888999;
    display: block;
}

.user-part li .submenu.notification li:not(:last-child) {
    border-bottom: 1px solid #888999;
}

.user-part li .submenu.notification li a.white {
    color: #fff;
}

.user-part .box-btn{
    width: 180px;
}

.logo img {
    width: auto;
    height: 40px;
}

.progressBarStyleRed {
    background: #d20404;
}

.progress-exam {
    border: 1px solid #DDDEE8;
    box-sizing: border-box;
    border-radius: 5px;
    /* display: block; */
    height: 6px;
    max-width: 124px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2px;
    margin-top: 12px;
}


/* header css end */


/* sidebar css start  */

.sidebar {
    position: fixed;
    top: 60px;
    width: 80px;
    background: #FFFFFF;
    left: 0;
    height: 100%;
    z-index: 4;
}

.sidebar-menu li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 42px;
    height: 42px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
}

.hover-text {
    white-space: nowrap;
    position: absolute;
    left: -120%;
    border: 1px solid #DDDEE8;
    border-radius: 0 10px 10px 0;
    height: calc(100% + 2px);
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 15px;
    z-index: -1;
    letter-spacing: -0.005em;
    color: #333555;
    font-weight: 700;
    font-size: 14px;
    padding-left: 0;
    -webkit-transition: .3s;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
    background: #fff;
}

.sidebar-menu li a {
    background: #FFFFFF;
    border: 1px solid #DDDEE8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    -webkit-transition: .3s ease;
    transition: .3s ease;
    position: relative;
}

.sidebar-menu li a:hover .hover-text {
    left: 100%;
    opacity: 1;
    visibility: visible;
}

.sidebar-menu li a:hover {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: #fff;
    border-right-color: transparent;
}

.sidebar-menu li a span.menu-icon svg path {
    fill: #888999;
}

.sidebar-menu li.active a span.menu-icon svg path {
    fill: #333555;
}

.sidebar-menu li.active a {
    background: #F3F4FA;
}

.sidebar-menu li.active a:hover {
    background: #fff;
}

.sidebar-menu li a span.menu-icon {
    margin-bottom: 3px;
}

.sidebar-menu li a:hover span.menu-icon svg path {
    fill: #21CF9A;
}

.sidebar-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 28px;
}

.sidebar-menu li {
    margin-bottom: 20px;
}

.sidebar-menu li a::before {
    position: absolute;
    height: calc(100% - 2px);
    background: #fff;
    content: "";
    left: -21px;
    top: 0;
    width: 20px;
}


/* sidebar css end */


/* main css start  */

.content-block {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 30px;
}

.main-content-wrapper {
    margin-left: 80px;
    margin-top: 60px;
    overflow: hidden;
}
.result-page .main-content-wrapper{
    padding: 20px;
}

.right-sidebar-wrap {
    background: #fff;
    height: 100%;
    width: 100%;
    padding: 45px;
}

.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.main-left-wrapper {
    padding: 55px 60px;
}

.tertiary-title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: -0.005em;
    color: #888999;
    margin-bottom: 12px;
    text-transform: uppercase;
}

.exam-wrp {
    text-align: center;
}

.exam-wrp p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #333555;
    margin-bottom: 20px;
}

.exam-code {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: auto 100px;
    grid-gap: 20px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.exam-code-left input {
    background: #FFFFFF;
    border: 1px solid #DDDEE8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    height: 44px;
    width: 100%;
    text-align: center;
    padding: 5px;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #333555;
    font-weight: bold;
}

.exam-code-left label {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: #888999;
    text-align: left;
    display: block;
}

.box-btn {
    background: #FFAA40;
    border-radius: 10px;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #FFFFFF;
    border: 0;
    padding: 10px 15px;
    transition: all .3s;
}
.box-btn:hover{
    filter: brightness(0.9);
}

.exam-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: #333555;
}

.active-exam-wrap p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: #888999;
}

.active-exam-wrap p img {
    width: 15px;
    margin-right: 4px;
}

.active-exam-wrap p span {
    margin-right: 20px;
}

button.start {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #FFFFFF;
    width: 95px;
    padding: 0;
    height: 36px;
    padding-bottom: 2px;
}

.exam-table tr td:last-child {
    text-align: right;
}

.exam-table td {
    border-top: 0;
    border-bottom: 1px solid #DDDEE8;
    vertical-align: middle;
    padding-top: 10px;
    padding-bottom: 10px;
}

.content-block-wrap {
    margin-bottom: 35px;
}

.gude-content a {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: -0.005em;
    color: #333555;
    display: block;
    margin-bottom: 8px;
    -webkit-transition: .3s;
    transition: .3s;
}

.gude-content a:hover {
    color: #21CF9A;
}

.gude-content p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: #888999;
}

.single-guide {
    display: grid;
    grid-template-columns: 85px auto;
    grid-gap: 15px;
    margin-bottom: 20px;
}
.guide-img{
    position: relative;
}
.guide-img img {
    max-width: 100%;
    width: 100%;
}

.box-btn.style-2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #FFFFFF;
    background: #21CF9A;
    transition: .3s;
}


.bdr-bottom {
    border-bottom: 1px solid #DDDEE8;
}

.bdr-top {
    border-top: 1px solid #DDDEE8;
}

.single-guide.bdr-bottom {
    padding-bottom: 20px;
}

.single-guide.in-sidebar .guide-img img {
    border-radius: 5px;
}

.single-guide.in-sidebar .gude-content a {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    letter-spacing: -0.005em;
    color: #333555;
    transition: .3s;
}

.single-guide.in-sidebar .gude-content a:hover {
    color: #21CF9A;
}

.single-guide.in-sidebar {
    grid-template-columns: 65px auto;
}

.right-sidebar-block .tertiary-title {
    margin-bottom: 30px;
}

.footer-text {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #888999;
    padding: 24px 0;
}

.footer-text a {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #888999;
    text-decoration: underline;
    margin-left: 10px;
    display: inline-block;
}

.msg-popup-content h4 {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.005em;
    color: #888999;
    margin-bottom: 15px;
}

.msg-popup-content p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.005em;
    color: #333555;
    margin-bottom: 20px;
}

.exam-code-left textarea {
    background: #FFFFFF;
    border: 1px solid #DDDEE8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    height: 120px;
    padding: 15px;
    font-size: 16px;
}


/* b-calendar */


/* CSS calsender start */

.month {
    padding: 7px;
    width: 100%;
    background: #ffaa40;
    text-align: center;
}

.month ul {
    margin: 0;
    padding: 0;
}

.month ul li {
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.month .prev {
    float: left;
    padding-top: 10px;
}

.month .next {
    float: right;
    padding-top: 10px;
}

.weekdays {
    margin: 0;
    padding: 10px 0;
    background-color: #ddd;
}

.weekdays li {
    display: inline-block;
    width: 13.6%;
    color: #666;
    text-align: center;
}

.days {
    padding: 10px 0;
    background: #fafafa;
    margin: 0;
}

.days li {
    list-style-type: none;
    display: inline-block;
    width: 13.6%;
    text-align: center;
    margin-bottom: 5px;
    font-size: 12px;
    color: #777;
}

.cs-calender {
    margin-bottom: 25px;
}

.days li .active {
    padding: 5px;
    background: #ffaa40;
    color: white !important
}


/* Add media queries for smaller screens */


/*
@media screen and (max-width:720px) {
  .weekdays li, .days li {width: 13.1%;}
}

@media screen and (max-width: 420px) {
  .weekdays li, .days li {width: 12.5%;}
  .days li .active {padding: 2px;}
}

@media screen and (max-width: 290px) {
  .weekdays li, .days li {width: 12.2%;}
}
*/


/* CSS calsender end */


/* home page css start  */

.more {
    text-decoration-line: underline;
    color: #888999;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    display: inline-block;
    transition: .3s;
}

.more:hover {
    color: #21CF9A;
    text-decoration-line: underline;
}
.single-s-card-container{
    display:flex;
    align-items: center;
    flex-wrap: wrap;
}
.single-s-card-container>div {
    flex: 0 50%;
    padding: 10px;
  }
.single-s-card {
    width: 100%;
    background: #E3E4FF;
    border-radius: 10px;
    padding: 10px;
    display:flex;
    align-items: center;
    flex-wrap: wrap;

}

.single-s-card .s-img img {
    /* max-width: 50px; */
}

.single-s-card .s-img {
    min-height: 50px;
    /* margin-bottom: 21px; */

}

.single-s-card h4 {
    letter-spacing: -0.005em;
    color: #333555;
    font-size: 24px;
    /* margin-bottom: 21px; */
    margin-left:20px;
}

.light-indigo-bg {
    background: #BEEEFF;
}

.light-red-bg {
    background: #FFDBDF;
}

.box-btn.yellow-bg {
    background: #FFAA40;
    font-size: 18px;
}

.user-guide-wrap.home-pge p {
    color: #333555;
    font-size: 16px;
}

.primary-title h2 {
    letter-spacing: -0.005em;
    color: #333555;
    font-size: 24px;
}

.primary-title {
    margin-bottom: 24px;
}


/* home page css end */


/* profile page css start  */

.profile-photo-blk {
    background-size: cover;
    background-position: center;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    position: relative;
    height: 150px;
    padding: 15px;
}

.profile-photo {
    position: relative;
}

.profile-photo .change-photo {
    position: absolute;
    right: -4px;
    bottom: 28px;
    z-index: 2;
}

.change-photo {
    background: #21CF9A;
    width: 24px;
    height: 24px;
    border: 2px solid #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.change-photo input {
    position: absolute;
    max-width: 0;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
}

.edit-photo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-photo img {
    width: 15px;
}

.profile-photo {
    margin-top: -50px;
    max-width: 108px;
    margin-left: auto;
    margin-right: auto;
    max-height: 108px;
    margin-bottom: 12px;
}

.profile-photo-blk h4 {
    color: #333555;
    text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.5);
    margin-bottom: 0;
}

.profile-content-blk {
    background: #fff;
    padding: 50px 15px;
    padding-bottom: 20px;
}

#v-pills-tab a {
    text-align: right;
    letter-spacing: -0.005em;
    color: #333555;
    padding: 8px 20px;
}

#v-pills-tab a.active {
    background: transparent;
    font-weight: 700;
}

.input-inside input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #DDDEE8;
    box-sizing: border-box;
    border-radius: 10px;
    height: 32px;
    padding: 3px 15px;
    letter-spacing: -0.005em;
    color: #333555;
    font-size: 16px;
    font-weight: 700;
}

.input-inside input::-moz-placeholder,
.input-inside input::-webkit-placeholder {
    opacity: 1;
    color: #333555;
}

.input-inside label {
    width: 100%;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: #888999;
    padding-left: 34px;
    text-align: left;
}

.input-inside {
    margin-bottom: 12px;
}

#v-pills-tab {
    height: 100%;
    border-right: 1px solid #DDDEE8;
}

.pf-tab-content-wrap {
    padding-left: 30px;
    max-width: 550px;
}

.main-left-wrapper.flx-wrap {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-bottom: 0;
}

.main-left-wrapper.flx-wrap .footer-text {
    margin-top: auto;
}

.change-photo.for-cover-photo {
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 2;
}


/* profile page css end */


/* exam page css start  */


/* timer css start  */

.settings-area {
    margin-right: 10px;
    padding-right: 5px;
    border-right: 1px solid #DDDEE8;
}

.settings-area>.dropdown .btn {
    padding-left: .5rem;
    padding-right: .5rem;
}

.settings-area .dropdown-menu {
    min-width: 15rem;
}

.settings-area>.dropdown .dropdown-item span {
    width: 14px;
    text-align: center;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
}

.settings-area .dropdown-toggle::after {
    display: none;
}

.countdown {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 135px;
    max-width: 20rem;
    margin: 0 auto;
}

.countdown_red {
    color: #d20404 !important;
}

.countdown__item {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    min-width: 31%;
    margin-bottom: 1rem;
}

.countdown__item--large {
    -webkit-box-flex: 1;
    flex: auto;
    width: 100%;
    font-size: 2.75em;
}

.countdown__timer {
    background-color: transparent;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.005em;
    color: #333555;
    text-align: center;
}

.countdown__label {
    padding-top: 0;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #888999;
}

.countdown__item--large .countdown__label:before,
.countdown__item--large .countdown__label:after {
    content: '';
    display: block;
    height: 1px;
    background-image: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(0, 0, 0, 0.4)), to(transparent));
    background-image: linear-gradient(left, transparent, rgba(0, 0, 0, 0.4), transparent);
}


/* timer css end */

.exam-header-right {
    flex: 0 0 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.box-btn.style-2.in-header {
    background: #333555;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15px;
    height: 42px;
}

.box-btn.style-2.in-header:hover {
    background: #DC3545
}

.box-btn.style-2.in-header img {
    margin-right: 10px;
}

.timer-progress-wrap span {
    background: linear-gradient(90deg, #888999 12.57%, rgba(196, 196, 196, 0) 12.72%);
    border: 1px solid #DDDEE8;
    box-sizing: border-box;
    border-radius: 5px;
    display: block;
    height: 6px;
    max-width: 124px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2px;
    margin-top: 10px;
}

.exam-timer-wrap {
    position: relative;
    padding-left: 18px;
}

.exam-timer-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}

.exam-header-left {
    display: flex;
    align-items: center;
    flex: 0 0 50%;
    display: flex;
}

.exam-header-left .logo {
    margin-right: 15px;
    flex: 0 0 60px;
}

.exam-header-left .input-inside {
    position: relative;
    margin-bottom: 0;
}

.exam-header-left .input-inside .sc-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    right: 15px;
}

.exam-header-left .input-inside .sc-icon-small {
    color: #888999;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline;
    left: 0px;
    /* right: 53px; */
}
.input-inside .exam-name{
    margin-left: 42px;
}
.small-screen-exam-title{
    display: none;
    margin-bottom: 10px;
}
.exam-name h2{
    font-size: 1.1em;
    margin-bottom: 0;
    letter-spacing: -0.005em;
}
.exam-name h2:after {
    font-family: 'Font Awesome 5 Free';
    color: #888999;
    position: relative;
    left: 5px;
    content: "\f05a";
    font-weight: 900;
    font-size: 12px;
}

.notification-container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 420px;
    padding: 0 15px;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto
}

.notification-info {
    background-color: #454652;
}

.notification-info .title {
    font-size: 1em;
    color: #FFF;
    line-height: 1.2em;
    font-weight: 900;
    margin: 0 0 5px
}

#accordion label:after {
    content: url(http://image.noelshack.com/fichiers/2016/48/1480523700-arrow-dl.png);
    position: absolute;
    right: 0;
}


/* .exam-box {
	display: flex;
	flex-direction: row;
} */

.exam-question-wrap {
    background: #fff;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 0;
    /* height: calc(100vh - 144px); */
    width: 100%;
    overflow: hidden;
}
.result-page .exam-question-wrap{
    margin-right: 20px;
}
.exam-question-wrapper {
    display: flex;
    /* display: -webkit-box; */
    /* align-items: flex-start; */
}

.main-content-wrapper.exam-pge {
    margin-left: 0;
    margin-right: 0;
    padding: 20px;
}

.mcq-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 92px;
    padding: 7px 0;
    border-bottom: 1px solid #DDDEE8;
}
.mcq-header .filter-arrow {
    display: block;
    width: 16px;
    cursor: pointer;
    text-align: center;
}
.mcq-header .filter-arrow svg{

}
.result-page .mcq-header{
    height: 42px;
    justify-content: center;
}

.mcq-header p {
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #888999;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    width: 52px;
}

.mcq-header p span {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #888999;
    display: block;
}
.filter-null-text{
    font-size: 12px;
    padding: 10px;
    text-align: center;
    color: #ffaa40;
    line-height: 1.2em;
}
.filter-result-null-text{
    display: block;
    height: calc(100vh - 286px);
    text-align: center;
    padding: 20px;
    color: #888999;
}

.mcq-wrap {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 10px;
    flex-basis: 92px;
    flex-grow: 0;
    flex-shrink: 0;
    width: 92px;
}

.mcq-content-wrap {
    /* max-height: calc(100vh - 235px);
    overflow-y: auto; */
}

.mcq-option {
    background: #FFFFFF;
    border: 1px solid #DDDEE8;
    box-sizing: border-box;
    border-radius: 12px;
    width: 22px;
    height: 22px;
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #333555;
}

.sl-no {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: -0.05em;
    color: #888999;
    margin-right: 2px;
    min-width: 25px;
}

.mcq-content-wrap {
    /* padding-left: 12px;
    padding-right: 5px;
    padding-top: 14px;
    padding-bottom: 10px; */
}

.single-mcq-row {
    margin-top: 3px;
    margin-bottom: 3px;
    background: transparent;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
    transition: .3s;
    position: relative;
    width: auto;
    white-space: nowrap;
}

.trans-badge {
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: 9px;
}

.single-mcq-row.active,
.single-mcq-row:hover {
    background: #F3F4FA;
}

.single-mcq-row.active .sl-no,
.single-mcq-row:hover .sl-no {
    color: #21CF9A;
}

.all-question-btns button.box-btn {
    background: #FFFFFF;
    border: 1px solid #DDDEE8;
    box-sizing: border-box;
    border-radius: 10px;
    font-weight: bold;
    font-size: 10px;
    line-height: 8px;
    /* line-height: 1.5em; */
    height: 2.7em;
    text-align: center;
    letter-spacing: -0.005em;
    color: #333555;
    /* padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px; */
    padding: 5 1px;
    margin-bottom: 2px;
    margin-top: 2px;
    /* width: 70px; */
    /* overflow: hidden; */
    text-overflow: ellipsis;
    white-space: nowrap
}

.all-question-btns button.box-btn span {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 8px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #888999;
}

.question-header {
    border-bottom: 1px solid #DDDEE8;
    padding: 5px;
    /* white-space: nowrap; */
    /* overflow: overlay; */
}

.all-question-btns button:not(:last-child) {
    margin-right: 5px;
}

.all-question-btns button.box-btn.active {
    background: #888999;
    border-color: #888999;
    color: #fff;
}

.mcq-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #DDDEE8;
    padding: 7px;
}

.mcq-footer label {
    display: block;
    margin-bottom: 3px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #888999;
    text-align: center;
}

.mcq-footer select {
    width: 50px;
    font-size: 12px;
    height: 21px;
    border: 1px solid #dddee8;
}

.exam-status-wrap {
    width: 50px;
}

.all-type-btn {
    background: #FFFFFF;
    border: 1px solid #DDDEE8;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 20px;
}

.all-type-btn button img {
    max-width: 100%;
}

.all-type-btn button:not(:last-child) {
    border-bottom: 1px solid #DDDEE8;
}

.all-type-btn button {
    padding: 12px;
    background: #fff;
    border: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
    letter-spacing: -0.005em;
    color: #888999;
}

.all-type-btn button:first-child {
    border-radius: 10px 10px 0 0;
}

.all-type-btn button:last-child {
    border-radius: 0 0 10px 10px;
}

.all-type-btn.gradee button.text-black {
    color: #333555;
}

.all-type-btn button.active {
    background: #888999;
}

.question-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    height: calc(100vh - 186px);
}

.answer-optons label {
    background: #FFFFFF;
    border: 1px solid #DDDEE8;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: #333555;
    /* height: 40px; */
    display: flex;
    align-items: center;
    padding: 5px 10px;
    position: relative;
    cursor: pointer;
    max-width: 98%;
}

.answer-optons label .mcq-option {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 28px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: -0.005em;
    color: #333555;
    margin-right: 10px;
    flex-shrink: 0;
}

.answer-optons label input {
    position: absolute;
    opacity: 0;
    max-width: 0;
    max-height: 0;
    visibility: hidden;
}
.result-page .answer-optons label{
    cursor: not-allowed;
}

.answer-optons label p{
    line-height: 1.2em;
    font-size: 1.5rem;
}

.question-text p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: #000000;
    margin-bottom: 25px;
    /* max-width: 99%; */
}

.question-text p span {
    font-size: unset;
    /* line-height: 1.5; */
}

.ql-formula {
    font-size: 1.5em !important;
}

.question-text h4 {
    font-size: 16px;
    margin-bottom: 26px;
}

.single-question {
    display: flex;
}

.question-sl {
    flex: 0 0 40px;
    text-align: center;
    padding-right: 28px;
}

.question-sl .question-number {
    display: block;
    text-align: center;
    margin-bottom: 15px;
    width: 30px;
    letter-spacing: -0.075em;
    position: relative;
    cursor: pointer;
}
.result-page .question-sl img {
    cursor: not-allowed !important;
}
.question-text-wrap{
    padding-right: 20px;
}

.twin-btn-wrap {
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px 0;
    background: #FFF;
    border-top: 1px solid #dddee8;
    -webkit-box-shadow: 0px -1px 5px rgba(51, 53, 85, 0.1);
    box-shadow: 0px -1px 5px rgba(51, 53, 85, 0.1);
    z-index: 4;
}

.twin-btn-wrap a {
    height: 34px;
    padding: 3px 14px !important;
}

.twin-btn-wrap a:first-child {
    border: 1px solid #888999;
    background: transparent;
    /* color: #888999; */
    margin-right: 10px;
}

.twin-btn-wrap a:first-child:hover {
    background: #888999;
    color: #fff;
}

.twin-btn-wrap a:nth-child(2) {
    background: #888999;
    border: 1px solid #888999;
}

.twin-btn-wrap a:nth-child(2):hover {
    background: #21CF9A;
    border: 1px solid #21CF9A;
}

.exam-header-left .input-inside input {
    color: #888999;
    font-weight: normal;
}


/* exam page css end */


/* exam-more-question page css start  */

.all-type-btn button.active svg rect {
    fill: #fff;
}

.all-type-btn button.active svg path {
    fill: #fff;
}

.all-type-btn button svg {
    max-width: 100%;
    max-height: 30px;
}

.box-btn.style-2.gray-bg.red-bg {
    background: #DC3545;
    border-color: #DC3545;
}

.c-modal-inner-wrap.max-500 {
    max-width: 500px;
}

.box-btn.style-2.gray-bg.red-bg:hover {
    background: transparent;
    color: #DC3545;
}

.exam-question-wrap.style-2 {
    flex: 0 0 calc(100% - 270px);
}

.mcq-wrap.wide {
    flex: 0 0 180px;
}

.mcq-wrap.wide .mcq-option.fill {
    background: #888999;
    color: #fff;
    border-color: #888999;
}

.mcq-wrap.wide .mcq-option {
    font-weight: normal;
    font-size: 12px;
}

.mcq-header.style-2 p {
    background: #F3F4FA;
    border: 1px solid #DDDEE8;
    box-sizing: border-box;
    border-radius: 10px;
    width: 33.33%;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 10px;
}

.single-question-scroll-wrap .single-question {
    margin-bottom: 50px;
    padding-top: 30px;
    position: relative;
}

.single-question-scroll-wrap {
    /* overflow-y: auto; */
}

.mcq-header.style-2 p.active {
    color: #21CF9A;
}

.answer-optons label.active {
    background: #DDDEE8;
    border: 1px solid #888999;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px rgba(51, 53, 85, 0.15);
}

.answer-optons label.active .mcq-option {
    background: #333555;
    border: 1px solid #DDDEE8;
    color: #fff;
}

.box-btn.style-2.in-header.red-bg {
    background: #DC3545;
}

.mcq-wrap.wide .mcq-option {
    margin-bottom: 6px;
}

.timer-progress-wrap.activee span {
    background: linear-gradient(90deg, #FFAA40 64.82%, rgba(196, 196, 196, 0) 64.99%);
}


/* exam-more-question page css end */


/* exam-end page css start  */

.exam-over-summary p {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: #000000;
    margin-bottom: 15px;
}

.exam-over-summary p a {
    color: #000;
    text-decoration: underline;
}

.box-btn.style-2.in-header.gray-bg {
    background: #888999;
}

.exam-over-summary {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 40px;
}

.main-content-wrapper.exam-pge.exam-end-pge {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(90vh - 80px);
}

.twin-btn-wrap {
    text-align: center;
    margin-top: auto;
}

.twin-btn-wrap a {
    color: #888999;
    margin: 0 5px;
    position: relative;
}

.twin-btn-wrap a.box-btn.style-2 {
    line-height: auto !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.twin-btn-wrap-disabled {
    cursor: not-allowed;
    background: #dddee8 !important;
    border: 1px solid #acacb6 !important;
    color: #acacb6 !important;
}
.twin-btn-wrap-disabled:hover{
    cursor: not-allowed;
    background: #dddee8 !important;
    border: 1px solid #acacb6 !important;
    color: #acacb6 !important;
    filter: brightness(1);
}

.twin-btn-wrap-enabled {
    cursor: pointer;
    border: 1px solid #888999;
    background: transparent !important;
}

.twin-btn-wrap-enabled:hover {
    border: 1px solid #21CF9A;
    background: #21CF9A !important;
    color: #FFF !important;
    /* Mustafa changed */
}

.question-answer p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: #000000;
}


/* exam-end page css end */

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.question-course {
    display: block;
    color: #878899;
    font-size: 9px;
    font-weight: 400;
    margin-left: -1px;
    left: 50%;
    top: -30px;
    position: absolute;
    border: 1px solid #dddee8;
    padding: 1px 6px;
    border-radius: 5px 5px 5px 0;
    background: rgba(255, 255, 255, .85);
    letter-spacing: normal;
    white-space: nowrap;
}

.question-course:before {
    content: '';
    display: block;
    border: 1px solid #dddee8;
    width: 7px;
    height: 7px;
    position: absolute;
    left: -1px;
    bottom: -7px;
    border-width: 7px 7px 0px 1px;
    border-right-color: transparent;
}

.question-course:after {
    content: '';
    display: block;
    border: 1px solid #fff;
    width: 6px;
    height: 6px;
    position: absolute;
    left: 0px;
    bottom: -5px;
    border-width: 6px 6px 0px 1px;
    border-right-color: transparent;
}

.exam-bottom {
    position: absolute;
    left: 20px;
    width: auto;
    text-align: left;
}
.questions-summary {
    color: #acacb6;
    line-height: 1;
    padding: 10px 0;
}
.summary-block{
    display: inline-block;
}
.questions-summary span{
    padding: 0 5px 0 0;
    font-size: 12px;
}
.questions-summary-number{
    font-weight: bold;
}
.course-select {
    display: none;
}
.back-btn{
    position: absolute;
    top: 2px;
    font-size: 11px;
    cursor: pointer;
    color: #21CF9A;
}
.back-btn:hover{
    color: #ffaa40;

}
.back-btn:focus,
.back-btn:active{
    outline: none;
    box-shadow: none;
}
.simple-bar {
    padding: 10px 20px 10px 30px;
}

.simple-bar-left {
    height: calc(100vh - 240px);
    padding: 10px;
}
.result-page .simple-bar-left{
    height: calc(100vh - 266px);
}
.result-page .question-content{
    height: calc(100vh - 212px);
}
.result-page .exam-question-wrap{
    height: calc(100vh - 170px);
}

.simplebar-scrollbar::before {
    background-color: #888999;
}
.post-img{
    max-width: 100%;
    object-fit: cover;
    padding-bottom: 25px;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  /* .box-btn.style-white {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #333555;
    background: #FFFFFF;
    transition: .3s;
    border: 1px solid #dddee8;
    margin-right: 50px;
    -webkit-box-shadow: 0px -1px 5px rgba(51, 53, 85, 0.1);
    box-shadow: 0px -1px 5px rgba(51, 53, 85, 0.1);
}
.box-btn.style-red {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #333555;
    background: #FFFFFF;
    transition: .3s;
    border-top: 1px solid #dddee8;
    -webkit-box-shadow: 0px -1px 5px rgba(51, 53, 85, 0.1);
    box-shadow: 0px -1px 5px rgba(51, 53, 85, 0.1);
} */
.box-btn.style-2.white {
    color: #333555;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15px;
    height: 42px;
    border: 1px solid #dddee8;
    -webkit-box-shadow: 0px -1px 5px rgba(51, 53, 85, 0.1);
    box-shadow: 0px -1px 5px rgba(51, 53, 85, 0.1);
}
/*
.box-btn.style-2.red:hover {
    background: #333555;
} */


.box-btn.style-2.red {
    background: #DC3545;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15px;
    height: 42px;
}

.box-btn.style-2.red:hover {
    background: #333555;
}
