body {
    font-family: 'Nunito Sans', Helvetica, sans-serif;
    color: #333555;
    background-color: #F3F4FA;
    letter-spacing: -0.005em;
}

header {
    height: 80px;
    background-color: #FFF;
    box-shadow: 0 1px 5px rgba(51, 53, 85, .1)
}

header nav .top-menu-list {
    margin: 0;
}

header nav .top-menu-list a {
    color: #333555;
    text-decoration: underline;
    transition: all .3s;
    padding: 10px;
    text-decoration: none;
}

header nav .top-menu-list a:hover {
    color: #21CF9A;
}

input.form-control {
    border: 1px solid #DDDEE8;
}

input[type="checkbox"] {
    display: none;
}

input[type="checkbox"]+label {
    position: relative;
    cursor: pointer;
    padding-left: 25px;
}

input[type="checkbox"]+label:before {
    position: absolute;
    left: 0;
    top: 2px;
    background: #fff;
    width: 18px;
    height: 18px;
    border: 1px solid #DDDEE8;
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    z-index: 1;
}

input[type="checkbox"]:checked+label:after {
    position: absolute;
    left: 3px;
    top: 1px;
    width: 18px;
    height: 18px;
    content: "";
    background: url('../img/check.svg') no-repeat center center;
    z-index: 2;
}

.nav-tabs a {
    text-decoration: none;
    transition: color .3s;
}

.lr-forms label {
    margin: 0;
    position: absolute;
    font-weight: normal;
    font-size: 12px;
    color: #888999;
    left: 13px;
    top: 1px;
}

.lr-forms small {
    position: absolute;
    top: -18px;
    left: 13px;
    font-size: 12px;
}

.lr-forms button {
    height: 50px;
}

form p {
    margin-bottom: .75rem;
}

.invalid-feedback {
    margin-top: 0;
}

.form-invalid {
    font-size: 80%;
}

.form-check {
    padding-left: 0;
    color: #888999;
}

.form-check label {
    left: 0;
    font-size: 16px;
}

.form-check-label a {
    color: #333555;
    text-decoration: underline;
    transition: all .3s;
}

.form-check-label a:hover {
    color: #21CF9A;
}

.form-group {
    position: relative;
    margin-bottom: .75rem;
}

.form-control {
    height: 50px;
    font-size: 18px;
    font-weight: bold;
    color: #333555;
    padding-top: 10px;
    border-radius: 10px;
}


/* */

.lr-forms-area,
.slider-area {
    height: calc(100vh - 80px);
}

.lr-forms-content {
    padding: 8vh 10vw 0 10vw;
}

.welcome {
    margin-bottom: 40px;
}

.welcome h2 {
    font-size: 1.8rem;
    font-weight: bold;
}

.welcome p {
    font-size: 14px;
    color: #888999;
}

.welcome a {
    color: #333555;
    text-decoration: underline;
    transition: all .3s;
}

.welcome a:hover {
    color: #21CF9A;
}

.lr-forms-area .nav-tabs {
    border-bottom: 2px solid #DDDEE8;
}

.lr-forms-area .nav-item {
    font-weight: bold;
    font-size: 20px;
    color: #333555;
    border: 0;
    margin-bottom: -2px;
}

.lr-forms-area .nav-item:hover {
    color: #21CF9A;
}

.lr-forms-area .nav-item.active {
    color: #333555;
    background-color: transparent;
    border-bottom: 2px solid #FFAA40;
}

.lr-forms-area .nav-link {
    padding-left: 1px;
    padding-right: 1px;
}

.lr-forms-area .nav-link:first-child {
    margin-right: 20px;
}

.lr-forms-area .tab-pane {
    padding: 40px 0;
}

.lr-forms-area .form-check {
    top: -5px;
}

.lr-forms-area button {
    width: 100%;
    background: #FFAA40;
    border-radius: 10px;
    border: 0;
    font-size: 18px;
    font-weight: bold;
    transition: all .3s;
}

.lr-forms-area button.orange-btn {
    background: #FFAA40;
}

.lr-forms-area button.orange-btn:hover {
    background: #FFA028;
}

.form-text-link {
    text-align: center;
}

.form-text-link a {
    color: #333555;
    text-decoration: underline;
    transition: all .3s;
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    margin: 15px auto;
}

.form-text-link a:hover {
    color: #21CF9A;
}

.slider-area {
    background-color: #333555;
}

.carousel-inner {
    height: calc(100vh - 80px - 10vh);
    padding: 10vh 0;
}

.carousel-indicators li {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-clip: border-box;
    border-top: 0;
    border-bottom: 0;
}

.carousel-indicators .active {
    background-color: #21CF9A;
}

.carousel-item {
    height: 100%;
    text-align: center;
}

.carousel-item img {
    width: 360px;
}

.carousel-caption {
    text-align: left;
}

.carousel-caption h5 {
    font-weight: bold;
    margin-bottom: 1em;
}

.carousel-caption p {
    margin: 0;
    line-height: 1.2;
}

@media (max-width: 767.98px) {
    .lr-forms-area,
    .slider-area {
        height: 100%;
    }
}

.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

    background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
