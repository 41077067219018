/* Normal desktop :1200px. */

@media (min-width: 1200px) and (max-width: 1500px) {}


/* Normal desktop :992px. */

@media (min-width: 992px) and (max-width: 1199.98px) {
    .main-left-wrapper {
        padding: 30px;
    }
    .right-sidebar-wrap {
        padding: 30px 20px;
    }
    .single-s-card {
        display: flex;
        /* margin-bottom: 30px; */
    }
    .pf-tab-content-wrap {
        padding-left: 0;
    }
}


/* Tablet desktop :768px. */

@media (min-width: 768px) and (max-width: 991.98px) {
    .toggle-bar {
        display: inline-block;
    }
    .main-left-wrapper {
        padding: 30px;
        padding-bottom: 30px;
    }
    .right-sidebar-wrap {
        padding: 30px 20px;
        background: transparent;
        padding-bottom: 0;
    }
    .single-s-card-container>div {
        flex: 0 50%;
        padding: 10px;
      }
    .single-s-card {
        display: flex;
        padding-top: 15px;
        margin-bottom: 30px;
    }
    .pf-tab-content-wrap {
        padding-left: 0;
        max-width: 100%;
    }
}


/* small mobile :320px. */

@media (min-width: 320px) and (max-width: 767.98px) {
    .toggle-bar {
        display: inline-block;
    }
    .main-left-wrapper {
        padding: 20px 15px;
        padding-bottom: 0;
    }
    .right-sidebar-wrap {
        padding: 30px 20px;
        background: transparent;
        padding-bottom: 0;
    }
    .content-block {
        padding: 15px;
    }

    .exam-wrp p{
        line-height: 1.2em;
    }
    .exam-table {
        width: 100%
    }
    .table-scroll-wrap {
        overflow-x: scroll;
    }
    .single-s-card {
        display: flex;
        padding-top: 15px;
        /* margin-bottom: 30px; */
    }
    .primary-title h2 {
        font-size: 22px;
    }
    .single-s-card h4 {
        font-size: 20px;
    }
    .single-s-card .s-img img {
        max-width: 40px;
    }

    .single-s-card .s-img {
        /* min-height: 40px; */
        /* margin-bottom: 21px; */

    }
    .pf-tab-content-wrap {
        padding-left: 0;
        max-width: 100%;
    }
    #v-pills-tab a {
        text-align: left;
        padding: 5px 0px;
    }
    #v-pills-tab {
        border-right: 0;
    }
    .profile-content-blk {
        padding: 15px 15px;
        padding-bottom: 0;
    }
    .main-left-wrapper.profile-pge {
        padding-top: 55px;
    }
    .result-page .question-content,
    .question-content{
        height: auto;
    }
    .result-page .exam-question-wrap{
        height: auto;
    }
    .all-question-btns button.box-btn {
        margin-bottom: 5px;
        margin-right: 5px;
    }
    .question-header {
        padding-bottom: 3px;
    }
    .exam-box {
        margin-bottom: 50px;
    }
    .exam-question-wrapper {
        /* display: block; */
        flex-direction: column;
        align-items: stretch;
    }
    .mcq-header {
        height: 82px;
        border-right: 1px solid #dddee8;
        border-bottom: 0;
    }
    .result-page .mcq-header{
        height: 82px;
    }
    .mcq-wrap {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: auto;
        overflow: hidden;
        order: 2;
        flex-basis: 82px;
    }
    .mcq-content-wrap{
        width: calc(100% - 157px);
    }
    .mcq-wrap .mcq-option-list{
        display: flex;
        width: 100%;
        height: 100%;
        padding: 12px 10px;
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .mcq-wrap .mcq-option-list:after{
        content: ' ';
        display: flex;
        flex: 0 0 10px;
    }
    .simple-bar {
        min-height: calc(100vh - 318px);
        padding: 10px 20px 10px 20px;
    }
    .mcq-wrap .simple-bar-left{
        height: auto;
        padding:0;
    }
    .mcq-wrap .simplebar-content-wrapper{
        /* width: 100%; */
    }
    .single-mcq-row {
        flex: 0 0 38px;
        text-align: center;
        margin: 1px;
        white-space: normal;
    }
    .main-content-wrapper.exam-pge {
        padding: 10px;
    }
    .mcq-footer {
        height: 82px;
        border-top: 0;
        border-left: 1px solid #dddee8;
        padding: 0 7px;
    }
    .trans-badge {
        position: absolute;
        left: 0;
        top: -8px;
        transform: translateY(-50%);
        width: 9px;
        right: 0;
        margin: auto;
    }
    .single-mcq-row .sl-no {
        margin-bottom: 5px;
        text-align: center;
    }
    .all-question-btns button.box-btn {
        padding-left: 10px;
        padding-right: 10px;
    }
    .exam-question-wrap {
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 45px;
        max-height: none;
        height: auto;
        order: 3;
    }
    .exam-status-wrap {
        margin-top: 20px;
    }
    .question-sl {
        flex: 0 0 30px;
        text-align: center;
        padding-right: 15px;
    }
    .twin-btn-wrap {
        text-align: right;
        padding-right: 10px;
    }
    .twin-btn-wrap a {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }
    .result-page .twin-btn-wrap {
        text-align: center;
    }
    .exam-bottom {
        left: 10px;
    }
    .questions-summary{
        padding: 5px 0;
    }
    .summary-block{
        display: block;
        width: auto;
    }
    .questions-summary-divider{
        display: none;
    }
    .all-type-btn {
        display: flex;
        flex-direction: column;
    }
    .big-screen-exam-title{
        display: none;
    }
    .small-screen-exam-title{
        display: block;
    }
    .exam-header-left h2{
        font-size: 1.2em;
    }
    .m-purpose {
        display: none;
    }
    .box-btn.style-2.in-header img {
        margin-right: 0;
    }
    .exam-header-left .logo {
        margin-right: 15px;
        flex: 0 0 40px;
    }
    .mobile-search i {
        font-size: 18px;
        cursor: pointer;
    }
    .c-modal-inner-wrap.max-500 {
        max-width: 88%;
    }
    .single-question-scroll-wrap {
        /* max-height: 667px; */
    }
    .question-text h4 {
        font-size: 14px;
        margin-bottom: 20px;
    }
    .answer-optons label {
        font-size: 13px;
    }
    .answer-optons label .mcq-option {
        width: 22px;
        height: 22px;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.005em;
        color: #333555;
        margin-right: 10px;
    }
    .question-text p {
        font-size: 14px;
        line-height: 21px;
    }
    .sl-no {
        font-size: 14px;
    }
    .countdown__timer {
        font-size: 18px;
        line-height: 18px;
    }
    .mcq-option {
        border-radius: 10px;
        width: 19px;
        height: 19px;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: -0.005em;
        color: #333555;
    }
    .mcq-wrap.wide .mcq-option {
        font-size: 11px;
    }
    .sl-no {
        font-size: 13px;
    }
    .box-btn.style-2 {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.005em;
        color: #FFFFFF;
        /* background: #21CF9A; */
        padding: 5px;
        transition: .3s;
    }
    .box-btn.style-2.in-header {
        border-radius: 10px;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 12px;
    }
    .exam-over-summary {
        order: 1;
        margin-bottom: 10px;
    }
    /* .course-select{
        display: inline;
    } */
}


/* Large Mobile :480px. */

@media (max-width: 576px) {
    .exam-code {
        display: block;
    }
    .exam-code-left input{
        margin-bottom: 5px;
    }
    .exam-code .box-btn{
        width: 95px;
        padding: 0;
        height: 36px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {}



