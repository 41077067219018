/* Deafult Margin & Padding */
/*-- Margin Top --*/
.mt-5 {
	margin-top: 5px;
}
.mt-10 {
	margin-top: 10px;
}
.mt-15 {
	margin-top: 15px;
}
.mt-20 {
	margin-top: 20px;
}
.mt-25 {
	margin-top: 25px;
}
.mt-30 {
	margin-top: 30px;
}
.mt-35 {
	margin-top: 35px;
}
.mt-40 {
	margin-top: 40px;
}
.mt-45 {
	margin-top: 45px;
}
.mt-50 {
	margin-top: 50px;
}
.mt-55 {
	margin-top: 55px;
}
.mt-60 {
	margin-top: 60px;
}
.mt-65 {
	margin-top: 65px;
}
.mt-70 {
	margin-top: 70px;
}
.mt-75 {
	margin-top: 75px;
}
.mt-80 {
	margin-top: 80px;
}
.mt-85 {
	margin-top: 85px;
}
.mt-90 {
	margin-top: 90px;
}
.mt-95 {
	margin-top: 95px;
}
.mt-100 {
	margin-top: 100px;
}
.mt-105 {
	margin-top: 105px;
}
.mt-110 {
	margin-top: 110px;
}
.mt-115 {
	margin-top: 115px;
}
.mt-120 {
	margin-top: 120px;
}
.mt-125 {
	margin-top: 125px;
}
.mt-130 {
	margin-top: 130px;
}
.mt-135 {
	margin-top: 135px;
}
.mt-140 {
	margin-top: 140px;
}
.mt-145 {
	margin-top: 145px;
}
.mt-150 {
	margin-top: 150px;
}
.mt-155 {
	margin-top: 155px;
}
.mt-160 {
	margin-top: 160px;
}
.mt-165 {
	margin-top: 165px;
}
.mt-170 {
	margin-top: 170px;
}
.mt-175 {
	margin-top: 175px;
}
.mt-180 {
	margin-top: 180px;
}
.mt-185 {
	margin-top: 185px;
}
.mt-190 {
	margin-top: 190px;
}
.mt-195 {
	margin-top: 195px;
}
.mt-200 {
	margin-top: 200px;
}
/*-- Margin Bottom --*/

.mb-5 {
	margin-bottom: 5px;
}
.mb-10 {
	margin-bottom: 10px;
}
.mb-15 {
	margin-bottom: 15px;
}
.mb-20 {
	margin-bottom: 20px;
}
.mb-25 {
	margin-bottom: 25px;
}
.mb-30 {
	margin-bottom: 30px;
}
.mb-35 {
	margin-bottom: 35px;
}
.mb-40 {
	margin-bottom: 40px;
}
.mb-45 {
	margin-bottom: 45px;
}
.mb-50 {
	margin-bottom: 50px;
}
.mb-55 {
	margin-bottom: 55px;
}
.mb-60 {
	margin-bottom: 60px;
}
.mb-65 {
	margin-bottom: 65px;
}
.mb-70 {
	margin-bottom: 70px;
}
.mb-75 {
	margin-bottom: 75px;
}
.mb-80 {
	margin-bottom: 80px;
}
.mb-85 {
	margin-bottom: 85px;
}
.mb-90 {
	margin-bottom: 90px;
}
.mb-95 {
	margin-bottom: 95px;
}
.mb-100 {
	margin-bottom: 100px;
}
.mb-105 {
	margin-bottom: 105px;
}
.mb-110 {
	margin-bottom: 110px;
}
.mb-115 {
	margin-bottom: 115px;
}
.mb-120 {
	margin-bottom: 120px;
}
.mb-125 {
	margin-bottom: 125px;
}
.mb-130 {
	margin-bottom: 130px;
}
.mb-135 {
	margin-bottom: 135px;
}
.mb-140 {
	margin-bottom: 140px;
}
.mb-145 {
	margin-bottom: 145px;
}
.mb-150 {
	margin-bottom: 150px;
}
.mb-155 {
	margin-bottom: 155px;
}
.mb-160 {
	margin-bottom: 160px;
}
.mb-165 {
	margin-bottom: 165px;
}
.mb-170 {
	margin-bottom: 170px;
}
.mb-175 {
	margin-bottom: 175px;
}
.mb-180 {
	margin-bottom: 180px;
}
.mb-185 {
	margin-bottom: 185px;
}
.mb-190 {
	margin-bottom: 190px;
}
.mb-195 {
	margin-bottom: 195px;
}
.mb-200 {
	margin-bottom: 200px;
}
/*-- Padding Top --*/

.pt-5 {
	padding-top: 5px;
}
.pt-10 {
	padding-top: 10px;
}
.pt-15 {
	padding-top: 15px;
}
.pt-20 {
	padding-top: 20px;
}
.pt-25 {
	padding-top: 25px;
}
.pt-30 {
	padding-top: 30px;
}
.pt-35 {
	padding-top: 35px;
}
.pt-40 {
	padding-top: 40px;
}
.pt-45 {
	padding-top: 45px;
}
.pt-50 {
	padding-top: 50px;
}
.pt-55 {
	padding-top: 55px;
}
.pt-60 {
	padding-top: 60px;
}
.pt-65 {
	padding-top: 65px;
}
.pt-70 {
	padding-top: 70px;
}
.pt-75 {
	padding-top: 75px;
}
.pt-80 {
	padding-top: 80px;
}
.pt-85 {
	padding-top: 85px;
}
.pt-90 {
	padding-top: 90px;
}
.pt-95 {
	padding-top: 95px;
}
.pt-100 {
	padding-top: 100px;
}
.pt-105 {
	padding-top: 105px;
}
.pt-110 {
	padding-top: 110px;
}
.pt-115 {
	padding-top: 115px;
}
.pt-120 {
	padding-top: 120px;
}
.pt-125 {
	padding-top: 125px;
}
.pt-130 {
	padding-top: 130px;
}
.pt-135 {
	padding-top: 135px;
}
.pt-140 {
	padding-top: 140px;
}
.pt-145 {
	padding-top: 145px;
}
.pt-150 {
	padding-top: 150px;
}
.pt-155 {
	padding-top: 155px;
}
.pt-160 {
	padding-top: 160px;
}
.pt-165 {
	padding-top: 165px;
}
.pt-170 {
	padding-top: 170px;
}
.pt-175 {
	padding-top: 175px;
}
.pt-180 {
	padding-top: 180px;
}
.pt-185 {
	padding-top: 185px;
}
.pt-190 {
	padding-top: 190px;
}
.pt-195 {
	padding-top: 195px;
}
.pt-200 {
	padding-top: 200px;
}
/*-- Padding Bottom --*/

.pb-5 {
	padding-bottom: 5px;
}
.pb-10 {
	padding-bottom: 10px;
}
.pb-15 {
	padding-bottom: 15px;
}
.pb-20 {
	padding-bottom: 20px;
}
.pb-25 {
	padding-bottom: 25px;
}
.pb-30 {
	padding-bottom: 30px;
}
.pb-35 {
	padding-bottom: 35px;
}
.pb-40 {
	padding-bottom: 40px;
}
.pb-45 {
	padding-bottom: 45px;
}
.pb-50 {
	padding-bottom: 50px;
}
.pb-55 {
	padding-bottom: 55px;
}
.pb-60 {
	padding-bottom: 60px;
}
.pb-65 {
	padding-bottom: 65px;
}
.pb-70 {
	padding-bottom: 70px;
}
.pb-75 {
	padding-bottom: 75px;
}
.pb-80 {
	padding-bottom: 80px;
}
.pb-85 {
	padding-bottom: 85px;
}
.pb-90 {
	padding-bottom: 90px;
}
.pb-95 {
	padding-bottom: 95px;
}
.pb-100 {
	padding-bottom: 100px;
}
.pb-105 {
	padding-bottom: 105px;
}
.pb-110 {
	padding-bottom: 110px;
}
.pb-115 {
	padding-bottom: 115px;
}
.pb-120 {
	padding-bottom: 120px;
}
.pb-125 {
	padding-bottom: 125px;
}
.pb-130 {
	padding-bottom: 130px;
}
.pb-135 {
	padding-bottom: 135px;
}
.pb-140 {
	padding-bottom: 140px;
}
.pb-145 {
	padding-bottom: 145px;
}
.pb-150 {
	padding-bottom: 150px;
}
.pb-155 {
	padding-bottom: 155px;
}
.pb-160 {
	padding-bottom: 160px;
}
.pb-165 {
	padding-bottom: 165px;
}
.pb-170 {
	padding-bottom: 170px;
}
.pb-175 {
	padding-bottom: 175px;
}
.pb-180 {
	padding-bottom: 180px;
}
.pb-185 {
	padding-bottom: 185px;
}
.pb-190 {
	padding-bottom: 190px;
}
.pb-195 {
	padding-bottom: 195px;
}
.pb-200 {
	padding-bottom: 200px;
}

/* Background Color */

.gray-bg {
	background: #f6f6f6;
}
.white-bg {
	background: #fff;
}
.black-bg {
	background: #222;
}
/* Color */

.white {
	color: #fff;
}
.black {
	color: #222;
}
/* black overlay */

[data-overlay] {
	position: relative;
}
[data-overlay]::before {
	background: #000 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}
[data-overlay="3"]::before {
	opacity: 0.3;
}
[data-overlay="4"]::before {
	opacity: 0.4;
}
[data-overlay="5"]::before {
	opacity: 0.5;
}
[data-overlay="6"]::before {
	opacity: 0.6;
}
[data-overlay="7"]::before {
	opacity: 0.7;
}
[data-overlay="8"]::before {
	opacity: 0.8;
}
[data-overlay="9"]::before {
	opacity: 0.9;
}


/* cModal Css Start  */
.c-modal-outer-wrap {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 53;
	opacity: 0;
	transition: .5s;
	visibility: hidden;
	background: #ffffff52;
	bottom: 0;
}
.c-modal-outer-wrap.cModal-open {
	opacity: 1;
	visibility: visible;
	overflow-x: hidden;
	overflow-y: auto;
  }
  .c-modal-outer-wrap.cModal-open .c-modal-inner-wrap {
	transform: scale(1);
}
.c-modal-outer-wrap::after {
	position: fixed;
	background: #000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	content: "";
	opacity: .5;
	z-index: 54;
	transition: .5s;
	cursor:pointer;
}
body.fixed-overlay {
	overflow: hidden;
}
.c-modal-inner-wrap {
	max-width: 600px;
	margin: auto;
	margin-top: 93px;
	transform: scale(.9);
	position: relative;
	z-index: 55;
	margin-bottom: 95px;
	transition: .5s;
}
.c-modal-header {
	padding: 15px;
	background: #fff;
	border-radius: 5px 5px 0 0;
	border-bottom: 1px solid #ddd;
	position: relative;
}
.c-modal-header h3 {
	margin-bottom: 0;
	font-size: 24px;
	font-weight: 600;
}
.cls-modal {
	position: absolute;
	right: -16px;
	top: -11px;
	cursor: pointer;
	background: #333555;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}
  .cls-modal i {
	font-size: 22px;
	color: #fff;
  }
  .c-modal-body {
	padding: 40px;
	background: #fff;
	border-radius: 5px;
}
/* cModal Css End */